import React, { forwardRef, useImperativeHandle } from "react";
import * as htmlToImage from "html-to-image";
import "./Receipt.css"; // Import CSS for styling

const Receipt = forwardRef(({ id, ...props }, ref) => {
  const shareReceipt = async () => {
    const receiptNode = document.getElementById(id); // Use the unique ID
    receiptNode.style.display = "block";

    try {
      if (window.AndroidInterface && window.AndroidInterface.shareReceipt) {
        const dataUrl = await htmlToImage.toPng(receiptNode);
        const base64Image = dataUrl.split(",")[1];
        window.AndroidInterface.shareReceipt(base64Image);
      } else {
        const dataURL = await htmlToImage.toPng(receiptNode, {
          skipFonts: true,
        });
        const link = document.createElement("a");
        link.download = `${timeStamp()}.png`;
        link.href = dataURL;
        link.click();
      }
    } catch (error) {
      console.error("Failed to generate or share receipt:", error);
    } finally {
      receiptNode.style.display = "none";
    }
  };

  const timeStamp = () => new Date().toISOString().replace(/[:.]/g, "-");

  useImperativeHandle(ref, () => ({
    shareReceipt,
  }));

  return (
    <div id={id} className="receipt">
      {" "}
      {/* Use dynamic ID */}
      <div className="header">
        <div style={{ fontSize: "18px" }}>
          <strong>{props.business}</strong>
        </div>
        <h1>
          <strong>{props.unit}</strong>
        </h1>
        <p style={{ color: props.status === "success" ? "green" : "orange" }}>
          {props.status === "success" ? "Successful" : "Processing"} Transaction
        </p>
        <p>
          <strong>{props.date}</strong>
        </p>
      </div>
      <div className="section">
        <h2>Recipient:</h2>
        <p>{props.recipient}</p>
      </div>
      <div className="section">
        <h2>Transaction Info</h2>
        <p>
          <strong>Transaction Type:</strong> {props.service.toUpperCase()}
        </p>
        <p>
          <strong>Transaction ID:</strong> {props.refNo}
        </p>
      </div>
      <footer>
        <p>Enjoy Seamless service.</p>
      </footer>
    </div>
  );
});

export default Receipt;
